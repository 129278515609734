import React, { Component } from 'react'
import { Card, Grid, Header } from 'semantic-ui-react'
import './Calendar.css'

export default class Calendar extends Component {
  render () {
    return (
      <div className="Calendar">
        <div className="Calendar-title">
          <Header as='h1'>Datas Importantes</Header>
        </div>
        <Grid stackable columns={3} id="Calendar-cards">
          <Grid.Row stretched>
          <Grid.Column>
              <Card fluid color='blue'>
                <Card.Content>
                  <Card.Header>Período de Inscrição</Card.Header>
                  <Card.Meta style={{fontWeight: "700", color: "#adadad"}}>13/08 a 23/09</Card.Meta>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid color='green'>
                <Card.Content>
                  <Card.Header>Realização das Provas</Card.Header>
                  <Card.Meta style={{fontWeight: "700", color: "#adadad"}}>05 de outubro</Card.Meta>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid color='yellow'>
                <Card.Content>
                  <Card.Header>Período de Contestação</Card.Header>
                  <Card.Meta style={{fontWeight: "700", color: "#adadad"}}>Em breve</Card.Meta>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}
