import React, { Component } from 'react'
import { Grid, Icon, Responsive } from 'semantic-ui-react'
import Columns from './../Columns/Columns'
import './Footer.css'

export default class Footer extends Component {
  render () {
    const linksOpei = [
      { 'name': 'Home', 'route': '/' },
      { 'name': 'Sobre', 'route': '/sobre' }

    ]

    const linksProvasAntigas = [
      { 'name': '2015', 'route': 'https://drive.google.com/drive/folders/1u2XnQajo6V74P_R9AwsXxrQ-bglvIjZt?usp=sharing' },
      { 'name': '2016', 'route': 'https://drive.google.com/drive/folders/1FJDXDxAQRU6ibrkAPucdqTEHz1GqTw8I?usp=sharing' },
      { 'name': '2017', 'route': 'https://drive.google.com/drive/folders/1PmowlX-JxBjBq41LUmTjqOHXW1dYhXs9?usp=sharing' },
      { 'name': '2018', 'route': 'https://drive.google.com/drive/folders/1hN5UcJjFYp66cqqi2xxtq-2hC7hfXH5t?usp=sharing' },
      { 'name': '2019', 'route': 'https://drive.google.com/drive/folders/12iTdKqGtr-dHHJBTLBxFgBiktsr0FrWy?usp=sharing'},
      { 'name': '2020', 'route': 'https://drive.google.com/drive/folders/1b1rmUpd6-QRC8HiWpZ9C9g0Abxwz7sr5?usp=sharing'},
      { 'name': '2021', 'route': 'https://drive.google.com/drive/folders/1XvaKnetC-LMXXGPbUPBds1RO9QJXAAIb?usp=sharing'},
      { 'name': '2022', 'route': 'https://drive.google.com/drive/folders/1FZ8LoBcpMLQB6H4wNXiPdq5-2zhPvr9q?usp=sharing'},
      { 'name': '2023', 'route': 'https://drive.google.com/drive/folders/1x967qrMke8-62sav4pNtrmwOD_x3k4n8?usp=sharing'},
    ]

    const linksInfos = [
      { 'name': 'Perguntas Frequentes', 'route': '/sobre#perguntas-frequentes' },
      // {"name": "Divulgue", "route": "/"},
      { 'name': 'Regulamento', 'route': 'https://opei.cin.ufpe.br/2024' }
    ]

    const footerDesktop = [

      <div className="Footer">
        <div className="Footer-top">
          <Grid className="Footer-Grid-1" stackable>
            <Grid.Row className="Grid-row1" columns={2}>
              <Grid.Column>
                <Grid stackable>
                  <Grid.Row columns={3}>
                    <Grid.Column>
                      <div className="Footer-OPEI">
                        <Columns title="OPEI" data={linksOpei} />
                      </div>
                    </Grid.Column>
                    <Grid.Column>
                      <div className="Footer-provas">
                        <Columns title="Edições Anteriores" data={linksProvasAntigas}/>
                      </div>
                    </Grid.Column>
                    <Grid.Column>
                      <div className="Footer-info">
                        <Columns title="Informações" data={linksInfos}/>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column>
                <div className="Footer-contact">
                  <div className="Footer-contact-title">
                    <h3>Contato</h3>
                  </div>
                  <div className="index-anchor" id="contato" style={{ marginTop: 0 }}>
                    <p>Av. Jorn. Aníbal Fernandes - Cidade Universitária, Recife - PE, 50740-560</p>
                    <p>opei@cin.ufpe.br</p>
                  </div>

                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </div>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width={5}>
                  <div className="Footer-pet-rights">
                    <p>© 2024 OPEI - Todos os direitos reservados</p>
                  </div>
                </Grid.Column>
                <Grid.Column width={5}>
                  <div className="Footer-pet-logo">

                  </div>
                </Grid.Column>

                <Grid.Column width={5}>
                  <div className="Footer-pet-social">

                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/opeinformatica">
                      <Icon name="instagram" size="large" inverted></Icon>
                    </a>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          <div>
        </div>    
      </div>
    ]

    const footerMobile = [
      <div className="Footer">
        <div>
          <Grid className="Footer-Grid-1">
            <Grid.Row className="Grid-row1" columns={2}>
              <Grid.Column>
                <Grid stackable>
                  <Grid.Row>
                    <Grid.Column>
                      <div className="Footer-OPEI">
                        <Columns title="OPEI" data={linksOpei} />
                      </div>
                    </Grid.Column>

                    <Grid.Column>
                      <div className="Footer-provas">
                        <Columns title="Provas Antigas" data={linksProvasAntigas}/>
                      </div>
                    </Grid.Column>

                    <Grid.Column>
                      <div className="Footer-info">
                        <Columns title="Informações" data={linksInfos}/>
                      </div>
                    </Grid.Column>

                    <Grid.Column>
                      <div className="Footer-contact-mobile">
                        <div className="Footer-contact-title">
                          <h3>Contato</h3>
                        </div>
                        <div className="index-anchor" id="contato" style={{ marginTop: 0 }}>
                          <p>Av. Jorn. Aníbal Fernandes - Cidade Universitária, Recife - PE, 50740-560</p>
                          <p>opei@cin.ufpe.br</p>
                        </div>
                      </div>
                    </Grid.Column>
                    
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </div>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width={5}>
                  <div className="Footer-pet-rights">
                    <p>© 2024 OPEI - Todos os direitos reservados</p>
                  </div>
                </Grid.Column>
                <Grid.Column width={5}>
                  <div className="Footer-pet-logo">

                  </div>
                </Grid.Column>

                <Grid.Column width={5}>
                  <div className="Footer-pet-social">

                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/opeinformatica">
                      <Icon name="instagram" size="large" inverted></Icon>
                    </a>

                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/opeinformatica/">
                      <Icon name="facebook" size="large" inverted></Icon>
                    </a>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          <div>
        </div>    
      </div>
    
    ]

    return (
      <div>
        <Responsive minWidth={992}>
          {footerDesktop}
        </Responsive>
        <Responsive maxWidth={992}>
          {footerMobile}
        </Responsive>
      </div>
     
    )
  }
}
